import snakecaseKeys from "snakecase-keys";
import { Ref, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { fetchInAppTargetUsers } from "@/api/inapps/targetUsers";
import { fetchPushMessageTargetUsers } from "@/api/pushnotifications/pushmessage/targetUsers";
import { buildTargetFiltersFromQueryParams } from "@/api/target/filters";
import { fetchTargets as apiFetchTargets } from "@/api/target/list";
import {
  Target,
  TargetFilters,
  TargetInApp,
  TargetType,
} from "@/api/target/typedefs";
import { User } from "@/api/users/typedefs";
import { DataState } from "@/composables/typedefs";
import {
  buildPaginationFromQueryParams,
  buildQueryParamsFromPagination,
  Pagination,
} from "@/lib/pagination";
import { assertNever } from "@/lib/typing";

import { useTargetsPermissions } from "./useTargetPermissions";

interface useTargetsOptions {
  targetType: Ref<TargetType>;
  product: Ref<number>;
  productLine: Ref<number>;
}

export function useTargets(options: useTargetsOptions) {
  const router = useRouter();
  const route = useRoute();
  const queryParams = router.currentRoute.value.query;

  const state: Ref<DataState> = ref(DataState.INITIAL);
  const targets: Ref<Target[] | TargetInApp[]> = ref([]);
  const filters: Ref<TargetFilters> = ref(
    buildTargetFiltersFromQueryParams(queryParams)
  );
  const pagination: Ref<Pagination> = ref(
    buildPaginationFromQueryParams(queryParams)
  );
  router.push({
    query: {
      ...snakecaseKeys(filters.value),
      ...buildQueryParamsFromPagination(pagination.value),
    },
  });

  const tryToFetchTargets = async () => {
    state.value = DataState.LOADING;
    try {
      const { data, count } = await apiFetchTargets(
        options.targetType.value,
        options.productLine.value,
        {
          ...snakecaseKeys(filters.value),
          ...buildQueryParamsFromPagination(pagination.value),
        }
      );
      targets.value = data;
      pagination.value.rowsNumber = count;
      state.value = DataState.LOADED;
    } catch (error: unknown) {
      targets.value = [];
      state.value = DataState.ERROR;
      throw error;
    }
  };
  const initialFetchPromise = tryToFetchTargets();

  const handleTargetSelect = async (targetId: number) => {
    await router.push({
      path: `/${options.targetType.value}/target/${options.product.value}/${options.productLine.value}/${targetId}`,
    });
  };

  const handleAddNewTarget = async () => {
    // TODO(PNS-1534): use named route when implemented
    await router.push({
      path: `/${options.targetType.value}/target/${options.product.value}/${options.productLine.value}`,
    });
  };

  const onFiltersChange = async (newFilters: TargetFilters) => {
    filters.value = newFilters;
    await router.push({
      query: { ...route.query, ...snakecaseKeys(newFilters) },
    });
    await tryToFetchTargets();
  };

  const onPaginationChange = async (newPagination: Pagination) => {
    pagination.value = newPagination;
    await router.push({
      query: {
        ...route.query,
        ...buildQueryParamsFromPagination(newPagination),
      },
    });
    await tryToFetchTargets();
  };

  const { hasAddTargetPermission } = useTargetsPermissions({
    productLine: options.productLine,
    targetType: options.targetType,
  });

  watch([options.targetType, options.product, options.productLine], () =>
    tryToFetchTargets()
  );

  const createdByOptions = ref<string[]>([]);
  watch(
    [options.targetType, options.productLine],
    async () => {
      createdByOptions.value = [];
      let users: User[];
      if (options.targetType.value === "inapps") {
        users = await fetchInAppTargetUsers(options.productLine.value);
      } else if (options.targetType.value === "pushnotifications") {
        try {
          users = await fetchPushMessageTargetUsers(options.productLine.value);
        } catch (e) {
          users = [];
        }
      } else {
        assertNever(options.targetType.value);
      }
      createdByOptions.value = users.map((u) => u.username);
    },
    { immediate: true }
  );

  return {
    state,
    targets,
    handleTargetSelect,
    handleAddNewTarget,
    onFiltersChange,
    onPaginationChange,
    initialFetchPromise,
    filters,
    pagination,
    hasAddTargetPermission,
    createdByOptions,
  };
}
